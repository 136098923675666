export default {
	id: "ID",
	enable: "启用",
	disable: "禁用",
	back: "返回",
	add: "新增",
	warning: "警告",
	publishAttention: "发布提醒",
	status: "状态",
	releaseStatus: "发布状态",
	relatedStatus: "已关联",
	addTime: "添加时间",
	sort: "排序",
	completeSort: "完成排序",
	operate: "操作",
	edit: "编辑",
	view: "查看",
	leads: "商机",
	delete: "删除",
	change: "更换",
	batchDel: "批量删除",
	cancel: "取消",
	prevStep: "上一步",
	nextStep: "下一步",
	backModify: "返回修改",
	confirm: "确认",
	close: "关闭",
	search: "搜索",
	reset: "重置",
	login: "登录",
	pleaseInput: "请输入",
	pleaseSelect: "请选择",
	release: "发布",
	immediateRelease: "立即发布",
	cancelRelease: "取消发布",
	releaseAgain: "再次发布",
	unrelease: "未发布",
	released: "已发布",
	unreleased: "未发布",
	operation: "操作",
	save: "保存",
	submit: "提交",
	preview: "预览",
	create: "创建",
	import: "导入",
	detail: "详情",
	all: "全部",
	log: "日志",
	transfer: "转移",
	auditPassed: "审核通过",
	auditNotPassed: "审核不通过",
	refundAndCancel: "退款并取消",
	downloadOrder: "下载订单",
	completeDeal: "完成处理",
	orderShip: "订单发货",
	dollar: "美元",
	effectiveTime: "生效时间",
	effectiveImmediately: "立即生效",
	timingEffective: "定时生效",
	trainClientSystem: "培训中心前台",
	leadsClientSystem: "线索平台前台",

	createTime: "创建时间",
	delTime: "删除时间",
	startTime: "开始时间",
	endTime: "结束时间",
	uploadImgTips: "请上传图片",
	check: "查看",
	acceptFormat: "支持格式",
	uploadFile: "上传文件",
	yes: "是",
	no: "否",
	unfold: "展开",
	fold: "收起",
	placeholderUsername: "请输入用户名",
	placeholderPassword: "请输入密码",
	upload: "上传",
	maxUploadSize: "超过最大上传文件大小 500M",
	collapse: "收起",
	expand: "展开",
	more: "更多",
	copy: "复制",
	tip: "提示",
	noData: "数据为空",

	tips: {
		warning: "温馨提示",
		modify: "你确定要修改这个状态吗？",
		delete: "你确定要删除这一行吗？",
		confirm: "确定",
		cancel: "取消",
		enableItemConfirm: "是否确定启用该选项?",
		disableItemConfirm: "是否确定禁用该选项?"
	},

	rules: {
		username: "请输入用户名",
		password: "请输入密码"
	},
	username: "用户名",
	name: "姓名",
	password: "密码",
	logoutTip: "您是否确认退出登录？",
	logoutSuc: "退出登录成功！",

	fileFormatError: "文件格式错误！",
	fileSuc: "文件上传成功！",
	fileFailed: "文件上传失败，请您重新上传！",
	fileSizeLimitTip: "上传图片大小不能超过",
	fileNumLimitTip: "文件数超出限制，请移除后上传！",
	pageNotFound: "抱歉，您访问的页面不存在~",
	backToHome: "回到首页",
	timeoutTip: "请求超时！请您稍后重试",

	installer: "安装商",
	homeowner: "房主",
	distributor: "分销商",
	verified: "已认证",
	unverified: "未认证",
	waitForManualVerified: "待人工认证",

	superAdmin: "超级管理员",
	primaryAccount: "一级账号",
	subAccount: "子账号",
	permanentAccount: "永久账号",
	temporaryAccount: "临时账号",
	fwhSubAccount: "厂商子账号",

	colSettings: "列设置",
	colName: "列名",
	display: "显示",
	sortCol: "排序",
	sortByCol: "按列排序",
	noCols: "暂无可配置列",
	us: "美国",
	download: "下载",
	matching: "匹配",
	mark: "标记",
	copyLink: "复制地址",

	country: "所属国家",
	timeLimit: "时间范围",
	people: "人",
	filterWords: "筛选关键词"
};
