export default {
	crmOpportunityCode: "CRM商机编号",
	opportunityCode: "商机编号",
	owner: "所有者",
	collaborator: "合作者",
	prograss: "进展",
	confidential: "保密",
	isUnusual: "是否异常",
	isRefuse: "是否拒绝",
	message: "消息",
	website: "站点",
	leadsId: "线索 id",
	transmissionFromCRM: "从 CRM 传输",

	mark: "标记",
	company: "公司",
	agent: "代理人",
	chooseIntervene: "选择介入后的结果",
	reallocate: "重新分配安装商",
	communicateSucceed: "沟通成功，恢复正常状态",
	irreversible: "无法挽回，线索失效",

	pleaseInputInterveneRecord: "请输入介入记录",
	remark: "备注",
	searchByDistance: "根据距离搜索",
	km: "KM",
	miles: "{distanceMiles} ≤ 英里",
	searchByGrade: "根据等级搜索",
	searchByName: "根据名称搜索",
	serviceScope: "服务范围",
	copySuccess: "复制成功",
	changeInstaller: "更换安装商",
	matchInstaller: "匹配安装商",
	emailInstaller: "发送邮件给安装商",
	emailHomeowner: "发送邮件给房主",
	matchOrder: "匹配次序",
	installer: "安装商",
	matchTime: "匹配时间",
	recycledTime: "回收时间",
	recycledReason: "回收原因",
	keepSecret: "信息保密",
	closeOpportunity: "关闭商机",
	opportunityResult: "商机结果",
	transactionsCount: "成交台数",
	closeReason: "关闭原因",
	selectOpportunityResult: "请选择商机结果",
	enterTransactionsCount: "请输入成交台数",
	chooseCloseReason: "请选择关闭原因",
	enterRemark: "请输入备注",
	deal: "成交",
	fail: "失败",
	emailTemplate: "邮件模板",
	chooseEmailTemplate: "请选择邮件模板",
	emailTitle: "邮件标题",
	emailContent: "邮件内容",
	enterEmailTitle: "请输入邮件标题",
	enterEmailContent: "请输入邮件内容",
	dealwith: "处理",
	addRecord: "添加记录",
	enterRecord: "请输入记录",
	role: "角色",
	emailTo: "收件人",
	missPersonInfo: "缺少个人姓名，电话信息或邮箱，请先完善个人信息",
	clickToComplete: "点击去完善",
	viewDetails: "查看详情",
	sendTo: "发送给",
	activityRecord: "活动记录",
	emailRecord: "邮件记录",
	longitudeLatitude: "经纬度",
	star: "星级",
	matchRecord: "匹配记录",
	locationInfomation: "位置信息",
	projectBackground: "项目背景",
	matchSuccessful: "匹配成功",
	replacementSuccessful: "更换成功",
	pleaseChooseIntervene: "请选择介入结果",
	maxOneThousandCharacter: "最多输入1000字符",
	repurchase: "业主转买其它竟品",
	cancelBuy: "业主取消购买计划",
	noResponse: "业主长期无反应",
	transactionsCountPositiveInteger: "数量范围0到99999",
	feedbackDescription: "{role}反馈问题描述",
	winInfo: "赢单信息",
	selectInstaller: "是否确认选择该安装商?",
	notSatisfiedInstaller: "没有匹配到满意的安装商?",
	clickHere: "点击这里",
	unableGetAddress: "该线索无地址信息",
	unableGetLongitudeLatitude: "房主地址信息无法定位，请使用安装商公司名搜索安装商并完成线索分配",

	waitMatch: "待分配",
	matched: "已分配",
	inProgress: "进行中",
	transactionComplete: "交易完成",
	failed: "失败",
	resetStatus: "重置状态",
	opportunityResetStatus: "商机状态重置",
	pleaseInputSuggestion: "请输入建议",
	communicateExceptionTips: "{from}反馈与{to}沟通出现问题，请尽快接入协助",
	completedAssistance: "已完成协助? 点击这里完成处理",
	addHomeownerFeedbackUrl: "添加房主反馈地址",
	poorCommunication: "出现沟通问题",
	communicationIssue: "沟通异常",
	pending: "待定",
	unassignedOrganization: "未分配",
	starRequired: "星级必填",
	starRange: "星级在0-5之间,最多保留一位小数",
	matchInstallerFirst: "请先匹配安装商",
	resetLeadsTips1: "如商机未分配安装商，重置后商机状态为“待分配安装商”",
	resetLeadsTips2: "如商机已分配安装商，但安装商未确认接收，重置后商机状态为“已分配”",
	resetLeadsTips3: "如商机已绑定安装商，重置后商机机状态为“进行中”，安装商端商机状态重置为“沟通中”",
	dealInformation: "成交信息",
	estimatedNumberSold: "预计售出数量",
	actualNumberSold: "实际售出数量",
	homeownerFeedbackDealwith: "房主反馈处理",
	confirmDealwithHoIssue: "确认已完成对房主反馈问题的处理",
	rejectTitle: "安装商已拒绝接收本条线索,拒绝原因: {reason}",
	emailToInstaller: "邮件通知安装商",
	emailToHomeowner: "邮件通知房主",
	hiddenPhone: "隐藏房主电话",
	refuseLeads: "安装商拒绝商机，请尽快处理",
	youCan: "您可以",
	issue: "异常",
	refuse: "拒绝",
	emailToPerson: "收件人",
	carbonCopy: "抄送人",
	enterEmailToPerson: "请输入收件人邮箱",
	emailFormatError: "邮箱格式存在错误",
	notAddDefault: "不能添加默认收件人",
	notAddSameEmail: "不能添加相同的邮箱",
	allLeadsCount: "线索总数量",
	processLeadsCount: "进展中线索数",
	completeLeadsCount: "成交线索数",
	lostLeadsCount: "输单线索数",
	completeCount: "成交台数",

	tips: {
		one: "一",
		two: "二",
		notice1: "已匹配安装商未及时响应，已在{emailNoticeTime}第{count}次邮件通知，请及时联系安装商.",
		notice2: "恭喜安装商已经完成了与房主的合同签约",
		notice3: "点击记录赢单信息",
		missAgentInfo: "无安装商代理人邮箱信息，补充该信息后才可开启该安装商",
		assignPermission: "是否确认更改该账号权限?"
	},

	rules: {
		remark: "请输入备注"
	}
};
