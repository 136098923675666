export default {
	modifyPassword: "Modify Password",
	username: "Username",
	email: "Email address",
	phone: "Phone",
	nameOfCompany: "Company name",
	department: "Department",
	position: "Position",
	role: "Role",
	companyNo: "Company Code ID",
	originPassword: "Original password",
	newPassword: "New password",
	confirmPassword: "Confirm Password",
	modifyPersonalInfo: "Modify Personal Info",
	rules: {
		name: "Name is required.",
		email: "Email is required.",
		emailformat: "Email format is incorrect.",
		phone: "Phone Number is required.",
		phoneFormat: "Phone Number format is incorrect.",
		password: "Password must be at least 6-40 characters and include at least one number and one letter",
		passwordLimitTips: "Password input format is incorrect",
		confirmPassword: "The entered passwords do not match",
		enterOriginPassword: "Please enter origin password",
		enterNewPassword: "Please enter new password",
		enterNewPasswordAgain: "Please enter new password again"
	}
};
