export default {
	add: "添加",
	modify: "修改",
	username: "用户名",
	password: "密码",
	confirmPassword: "确认密码",
	name: "名称",
	status: "状态",
	role: "角色",
	remark: "备注",
	lastIp: "最后登录ip",
	loginCount: "登录次数",
	lastLoginTime: "最后登录时间",
	operate: "操作",
	menuName: "菜单名称",
	menuAddress: "菜单地址",
	menuType: "菜单类型",
	parent: "父级",
	updateTime: "更新时间",
	chineseName: "中文名称",
	englishName: "英文名称",
	path: "路径",
	icon: "图标",
	sort: "排序",
	standalone: "是否独立展示",
	isDirectory: "是否是目录",
	permissionCodeOrPath: "权限码/地址",
	permissionCodeName: "权限码/地址名称",
	yes: "是",
	no: "否",
	showType: "显示类型",
	public: "公开",
	login: "登录",
	permission: "权限",
	accessType: "访问类型",
	addBy: "添加人",
	addTime: "添加时间",
	edit: "编辑",
	delete: "删除",
	modifyPassword: "修改密码",
	enable: "启用",
	disabled: "禁用",
	universalAccess: "公开",
	loginAccess: "登录",
	permissionAccess: "权限",
	permissionCode: "权限码",
	introduce: "介绍",
	ip: "IP",
	createUser: "创建用户",
	createAt: "创建时间",
	updateAt: "更新时间",
	keywords: "关键词",
	belongsDepartment: "所属部门",
	crmID: "crmID",
	crmName: "crm姓名",
	crmRole: "crm角色",
	position: "职位",
	bindingStatus: "绑定状态",
	unbound: "未绑定",
	bound: "已绑定",
	fuzzyName: "输入用户姓名、ID、工号搜索",
	bindFuzzyName: "输入用户名，姓名搜索",
	department: "部门",
	departmentLeader: "部门领导",
	jobNumber: "工号",
	boundCRMAccount: "已绑定CRM账号",
	toBinding: "去绑定",
	parentDepartment: "上级部门",
	departmentName: "部门名称",
	sortOrder: "排序",
	bind: "绑定",
	bindPlateformAccount: "关联平台账号",
	bindingAccount: "绑定账号",
	choosePlateformAccount: "选择平台账号",
	confirmBinding: "确认绑定",
	plateformAccountInfo: "平台账号信息",
	crmAccountInfo: "CRM 账号信息",
	organizationTree: "组织架构树",
	filterWords: "过滤关键词",

	rules: {
		username: "用户名为必填项",
		password: "密码为必填项",
		passwordRegExp: "至少8-16个字符，至少1个大写字母，1个小写字母和1个数字",
		name: "名称为必填项",
		status: "状态为必填项",
		mustNumber: "排序为必填项",
		isStandalone: "是否独立展示为必填",
		isDirectory: "是否目录为必填项",
		title: "中文名称为必填项",
		enTitle: "英文名称为必填项",
		path: "路径为为必填项",
		parent: "父级为必填项",
		icon: "图标为必填项",
		sort: "排序为必填项",
		standalone: "是否独立展示为必填项",
		permissionCodeOrPath: "权限码或路径为必填项",
		accessType: "访问类型为必填项",
		introduce: "介绍为必填项",
		permissionCode: "权限码为必填项",
		ip: "IP为必填项"
	},

	createSuccess: "创建成功",
	modifySuccess: "修改成功",
	refreshedAfter: "页面将在 2s 后跳转",

	message: {
		modifySuccess: "修改成功"
	},

	warning: "警告",
	installCertification: "安装认证",
	saleCertification: "销售认证",
	designCertification: "设计认证",
	completedCertification: "完成认证人数",
	backToList: "返回列表",
	transferCompleted: "转移完成",
	transferFailed: "失败原因：{msg}",
	accountId: "账号ID",

	transfer: {
		title: "认证转移说明:",
		line1:
			"选择需要转移的新老账号，转移完成后，新账号将获得老账号下已获得的认证证书、以及证书对应的各项权限，例如设备安装激活权限等",
		line2: "完成转移操作后，新账号对应的证书有效开始时间以转移完成时间为开始",
		line3: "老账号下的课程学习记录、考试结果等数据不进行数据转移",
		line4: "转移操作不可逆，请谨慎操作"
	},

	company: {
		companyName: "公司名称",
		companyNO: "公司编号",
		companyType: "公司类型",
		installer: "安装商",
		distributor: "分销商",
		operator: "运维商",
		certificationStatus: "认证状态",
		installCertificationStatus: "安装认证状态",
		baseInfo: "基本信息",
		companyFullName: "公司全称",
		companyEmail: "公司邮箱",
		companyPhoneNumber: "公司电话",
		country: "国家",
		state: "州",
		city: "城市",
		zipCode: "邮编",
		street: "街道",
		proxyPersonalInfo: "代理人信息",
		proxyPersonalName: "代理人姓名",
		proxyPersonalEmail: "代理人邮箱",
		proxyPersonalPhoneNumber: "代理人电话",
		position: "职位",
		businessInformation: "经营信息",
		businessHours: "公司经营时长",
		serviceArea: "服务地区",
		staffSize: "员工规模",
		electriciansNumber: "内部电工数量",
		contractorNumber: "承包商执照号码",
		noContractorReason: "无承包商执照原因",
		isAssociateDistributor: "是否关联分销商",
		companyWebsite: "公司网址",
		year: "年",
		years: "年",
		enableMatch: "线索匹配",
		leadsDispatch: "线索分发",
		star: "星级",
		longitude: "经度",
		latitude: "纬度",
		remark: "备注",
		leadsManager: "线索管理员",
		seller: "销售",
		sysAdmin: "系统管理员",
		certificateYes: "已认证",
		certificateNo: "未认证",
		salesManager: "销售负责人"
	},

	tips: {
		deleteMenu: "此操作将永久删除菜单，要继续吗？",
		deletePermissionCode: "此操作将永久删除此权限/地址，是否要继续？",
		deleteRole: "此操作将永久删除此权限码/地址，是否要继续？",
		deleteIp: "此操作将永久删除该 ip，是否继续？",
		manualAuthentication: "是否确认人工认证?",
		moreThenZero: "排序必须大于等于 0 且小于 100的整数",
		distanceLimit: "请输入正整数，最多5位数",
		distanceMinLimit: "距离必须大于等于0",
		maxValueFiveLimit: "请输入不超过5位的正整数",
		confirmDisabledOrganization: "确认禁用该组织?",
		confirmEnableOrganization: "确认启用该组织?",
		confirmDeleteOrganization: "确认删除该组织?",
		parentDepartment: "请选择上级部门",
		departmentName: "请输入部门名称",
		sortOrder: "请输入排序",
		sortOrderPlaceholder: "数字越小越向前",
		deleteWarning: "该部门下已有人员，{typeText}该部门前请先转移人员",
		pleaseChoosePlateformAccount: "请选择平台账号",
		hasChildDepartment: "该部门下已有子部门，请先删除子部门"
	},

	userList: {
		loginAccount: "登录账号",
		accountStatus: "账号状态",
		accountType: "账号类型",
		accountLevel: "账号级别",
		companyName: "公司名称",
		companyCode: "公司代码",
		userRole: "用户角色",
		agingType: "时效类型",
		userName: "用户名",
		phone: "联系方式",
		email: "邮箱地址",
		address: "地址",
		createTime: "创建时间",
		lastLoginTime: "上次登录时间",
		searchPlaceholder: "登录账号、邮箱、公司",
		internal: "内部",
		external: "外部",

		jobNo: "工号",
		departmental: "部门",
		country: "国家",
		account: "账号"
	},
	qualificationTransfer: {
		transferTime: "转移时间",
		viewRecord: "查看记录",
		add: "添加",
		change: "更改",
		oldAccount: "老账号",
		newAccount: "新账号",
		transfer: "转移",
		backToChange: "返回修改",
		confirmTransfer: "确认转移",
		transferConfirm: "认证转移确认",
		addAccountRule: "请添加账号",
		radio: "单选",
		sameAccount: "旧帐号和新账号不可为同一账号",
		operateTime: "操作时间",
		operateUserName: "操作人用户名",
		operateName: "操作人名称",
		transferRecord: "转换记录"
	}
};
