export default {
	basic: {
		courseTitle: "Custom course type management",
		mediaTitle: "Resource library video classification management",
		documentTitle: "Resource library document classification management",
		courseType: "Course type",
		mediaCate: "Media Category",
		documentCate: "Document Category",
		multiLanguage: "Multi-language",
		status: "Status",
		sort: "Sort",
		createTime: "Creation time",
		operation: "Operation",
		transfer: "transfer",
		cateEnName: "Category Name (English)",
		cateEsName: "Category Name (Spanish)",
		cateSort: "Category sorting",
		placeHolder1: "Required, up to 50 characters",
		pleaseInput: "Please input",
		pleaseSelect: "Please Select"
	}
};
