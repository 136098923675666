export default {
	name: "名称",
	userName: "姓名",
	email: "邮箱",
	phoneNO: "电话号码",
	companyName: "公司名称",
	downloadTime: "下载时间",
	fuzzySearch: "姓名，邮箱，电话号码",
	documentSearch: "文件检索",
	fileTitle: "文件标题",
	fileDescription: "文件描述",
	uploadFile: "上传文件",
	fileName: "文件名称",
	description: "描述",
	fileSize: "文件大小",
	downloadTimes: "下载次数",
	createTime: "创建时间",
	creator: "创建人",
	downloadFile: "下载文件",
	viewDocument: "查看下载文件",
	recordFuzzySearch: "By/Print Name/On behalf of Company/Email",
	country: "国家",
	by: "By",
	printName: "Print Name",
	onBehalfOfCompany: "On Behalf Of Company",
	phone: "电话",
	editFileDetail: "编辑文件详情",

	tips: {
		removeDocument: "是否确认删除该文档"
	}
};
