export default {
	crmOpportunityCode: "CRM Leads Code",
	opportunityCode: "Leads Code",
	owner: "Owner",
	collaborator: "Collaborator",
	prograss: "Progress",
	confidential: "Confidential",
	isUnusual: "Is Unusual",
	isRefuse: "Is Refuse",
	message: "Message",
	website: "Website",
	leadsId: "Leads ID",
	transmissionFromCRM: "Transmission from CRM",

	mark: "Mark",
	company: "Company",
	agent: "Agent",
	chooseIntervene: "Result after choosing to intervene",
	reallocate: "Reallocate installer",
	communicateSucceed: "Communication successful, back to normal status",
	irreversible: "Irreversible, lead invalid",

	pleaseInputInterveneRecord: "Please enter intervention record",
	remark: "Remark",
	searchByDistance: "Search by distance",
	km: "KM",
	miles: "{distanceMiles} ≤ Miles",
	searchByGrade: "Search by grade",
	searchByName: "Search by name",
	serviceScope: "Service scope",
	copySuccess: "Copy successful",
	changeInstaller: "Change installer",
	matchInstaller: "Match installer",
	emailInstaller: "Email installer",
	emailHomeowner: "Email homeowner",
	matchOrder: "Match order",
	installer: "Installer",
	matchTime: "Match time",
	recycledTime: "Recycled time",
	recycledReason: "Recycled reason",
	keepSecret: "Keep information confidential",
	closeOpportunity: "Close leads",
	opportunityResult: "Leads result",
	transactionsCount: "Number of traded",
	closeReason: "Close reason",
	selectOpportunityResult: "Please select leads result",
	enterTransactionsCount: "Please enter number of transactions",
	chooseCloseReason: "Please choose close reason",
	enterRemark: "Please enter remark",
	deal: "Deal",
	fail: "Fail",
	emailTemplate: "Email template",
	chooseEmailTemplate: "Please choose email template",
	emailTitle: "Email title",
	emailContent: "Email content",
	enterEmailTitle: "Please enter email title",
	enterEmailContent: "Please enter email content",
	dealwith: "Deal with",
	addRecord: "Add record",
	enterRecord: "Please enter record",
	role: "Role",
	emailTo: "Recipient",
	missPersonInfo: "Missing personal name, phone information or email, please complete your personal information first",
	clickToComplete: "Click to complete",
	viewDetails: "View details",
	sendTo: "Send to",
	activityRecord: "Activity record",
	emailRecord: "Email record",
	longitudeLatitude: "Longitude and Latitude",
	star: "Star",
	matchRecord: "Match Record",
	locationInfomation: "Location information",
	projectBackground: "Project Background",
	matchSuccessful: "Match successful",
	replacementSuccessful: "Replacement successful",
	pleaseChooseIntervene: "Please choose intervention result",
	maxOneThousandCharacter: "Maximum 1000 characters",
	repurchase: "Homeowner switches to buying other products",
	cancelBuy: "Homeowner cancels purchase plan",
	noResponse: "Homeowner has no response for a long time",
	transactionsCountPositiveInteger: "Quantity range 0 to 99999",
	feedbackDescription: "{role} feedback problem description",
	winInfo: "Win information",
	selectInstaller: "Are you sure you want to choose this installer?",
	notSatisfiedInstaller: "Didn't match a satisfactory installer?",
	clickHere: "Click here",
	unableGetAddress: "This lead has no address information",
	unableGetLongitudeLatitude:
		"Homeowner address information cannot be located, please use the installer's company name to search for the installer and complete the lead assignment",

	waitMatch: "Waiting for allocation",
	matched: "Allocated",
	inProgress: "In progress",
	transactionComplete: "Transaction complete",
	failed: "Failed",
	resetStatus: "Reset status",
	opportunityResetStatus: "Lead status reset",
	pleaseInputSuggestion: "Please enter suggestion",
	communicateExceptionTips:
		"The {from} reported that there was a problem communicating with the {to}, please contact us for assistance as soon as possible.",
	completedAssistance: "Completed assistance? Click here to complete the process",
	addHomeownerFeedbackUrl: "Add Homeowner Feedback Url",
	poorCommunication: "Poor Communication",
	communicationIssue: "Communication Issue",
	pending: "Pending",
	unassignedOrganization: "Unassigned Organization",
	starRequired: "Star is required",
	starRange: "Star rating ranges from 0 to 5, with up to one decimal place.",
	matchInstallerFirst: "Please match the installer first",
	resetLeadsTips1: `If the lead has not been assigned an installer, the lead status will be "To be assigned an installer" after reset.`,
	resetLeadsTips2: `If the lead has been assigned to an installer, but the installer has not confirmed receipt, the status of the lead will be "assigned" after reset.`,
	resetLeadsTips3: `If the lead has been bound to the installer, the status of the lead will be "In Progress" after reset, and the status of the lead on the installer's side will be reset to "Under Communication".`,
	dealInformation: "Deal Information",
	estimatedNumberSold: "Estimated Number Sold",
	actualNumberSold: "Actual Number Sold",
	homeownerFeedbackDealwith: "Homeowner feedback process",
	confirmDealwithHoIssue: "Confirm that the processing of feedback from homeowners has been completed",
	rejectTitle: "The installer has refused to accept this lead, Reason for rejection: {reason}",
	emailToInstaller: "Email notice to installer",
	emailToHomeowner: "Email notice to homeowner",
	hiddenPhone: "Hide the homeowner's phone number",
	refuseLeads: "Installer refused leads, please deal with it as soon as possible",
	youCan: "You can",
	issue: "Issue",
	refuse: "Refuse",
	enterEmailToPerson: "Please enter the recipient's email address",
	emailFormatError: "Email address format is incorrect",
	notAddDefault: "Unable to add default recipient",
	notAddSameEmail: "Unable to add the same email address",
	allLeadsCount: "Total number of leads",
	processLeadsCount: "In Progress",
	completeLeadsCount: "Deal with",
	lostLeadsCount: "Number of lost leads",
	completeCount: "Number of deal width",

	tips: {
		first: "first",
		second: "second",
		notice1:
			"The matched installer did not respond in time, The {count} email notification was sent at {emailNoticeTime}, please contact the installer as soon as possible.",
		notice2: "Congratulations, the installer has completed the contract signing with the homeowner",
		notice3: "Click to record win information",
		missAgentInfo: "No installer agent e-mail information, add this information to enable the installer",
		assignPermission: "Do you confirm to change the account permissions?"
	},

	rules: {
		remark: "Please input remark."
	}
};
