export default {
	basic: {
		courseTitle: "自定义课程类型管理",
		mediaTitle: "资源库视频分类管理",
		documentTitle: "资源库文档分类管理",
		courseType: "课程类型",
		mediaCate: "媒体分类",
		documentCate: "文档分类",
		multiLanguage: "多语言",
		status: "状态",
		sort: "排序",
		createTime: "创建时间",
		operation: "操作",
		transfer: "转移",
		cateEnName: "分类名称（英语）",
		cateEsName: "分类名称（西语）",
		cateSort: "分类排序",
		placeHolder1: "必填，最多50个字符",
		pleaseInput: "请输入",
		pleaseSelect: "请选择"
	}
};
