export default {
	name: "Name",
	userName: "Name",
	email: "Email",
	phoneNO: "Phone NO.",
	companyName: "Company Name",
	downloadTime: "Download Time",
	fuzzySearch: "Name、Email、Phone NO.",
	documentSearch: "Document Search",
	fileTitle: "File Title",
	fileDescription: "File Description",
	uploadFile: "Upload File",
	fileName: "File Name",
	description: "Description",
	fileSize: "File Size",
	downloadTimes: "Download Times",
	createTime: "Create Time",
	creator: "Creator",
	downloadFile: "Download File",
	viewDocument: "View Download",
	recordFuzzySearch: "By/Print Name/On behalf of Company/Email",
	country: "Country",
	by: "By",
	printName: "Print Name",
	onBehalfOfCompany: "On Behalf Of Company",
	phone: "Phone",
	editFileDetail: "Edit File Details",
	tips: {
		removeDocument: "Are you sure to delete this document"
	}
};
