export default {
	modifyPassword: "修改密码",
	username: "用户名称",
	email: "邮箱",
	phone: "电话",
	nameOfCompany: "公司名称",
	department: "部门",
	position: "职位",
	role: "角色",
	companyNo: "公司编码",
	originPassword: "原始密码",
	newPassword: "新密码",
	confirmPassword: "确认密码",
	modifyPersonalInfo: "修改个人信息",
	rules: {
		name: "请输入用户名称",
		email: "请输入邮箱",
		emailformat: "邮箱格式错误",
		phone: "请输入电话号码",
		phoneFormat: "电话号码格式错误",
		password: "密码必须为 6-40 个字符，并且至少包含一个数字和一个字母",
		passwordLimitTips: "密码输入格式不正确",
		confirmPassword: "两次密码输入不一致",
		enterOriginPassword: "请输入原始密码",
		enterNewPassword: "请输入新密码",
		enterNewPasswordAgain: "请再次输入新密码"
	}
};
